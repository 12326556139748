import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { filter, firstValueFrom } from 'rxjs';
import {
  AccountInfo,
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { WebApiConfig } from 'dataverse-webapi';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebApiFactoryService {
  private isInitialized = false;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  private async initialize() {
    // this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    // this.msalBroadcastService.msalSubject$
    //   .pipe(
    //     filter(
    //       (msg: EventMessage) =>
    //         msg.eventType === EventType.ACCOUNT_ADDED ||
    //         msg.eventType === EventType.ACCOUNT_REMOVED,
    //     ),
    //   )
    //   .subscribe(() => {
    //     if (this.authService.instance.getAllAccounts().length === 0) {
    //       window.location.pathname = '/';
    //     }
    //   });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    await firstValueFrom(this.authService.initialize());
  }
  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      const accountInfo = accounts[0];
      this.authService.instance.setActiveAccount(accountInfo);
    }
  }
  async createWebApiConfig() {
    if (!this.isInitialized) {
      await this.initialize();
    }

    if (typeof Xrm !== 'undefined') {
      const config = new WebApiConfig('9.2');
      return config;
    } else {
      let response: AuthenticationResult & { account: AccountInfo };
      try {
        response = await firstValueFrom(
          this.authService.acquireTokenSilent({
            ...this.msalGuardConfig.authRequest,
            scopes: environment.apiConfig.scopes,
          } as SilentRequest),
        );
      } catch (e) {
        response = await firstValueFrom(
          this.authService.acquireTokenPopup({
            ...this.msalGuardConfig.authRequest,
            scopes: environment.apiConfig.scopes,
          } as SilentRequest),
        );
      }
      const config = new WebApiConfig(
        '9.2',
        response.accessToken,
        // either the url is set by the custom.html file based on the xrm configuration

        (window as any).apiUri ?? environment.apiConfig.uri,
      );
      return config;
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
}
