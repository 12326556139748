export const commonEnvironment = {
  goodDifferenceThreshold: 1,
  sentry: 'https://4930d4567e18030bc398af47050b6bf4@us.sentry.io/4506700904988672',
  version: '0.0.0',
  showMenu: true,
  payoutPartnerRoleId: 'c9477834-8fb0-ee11-a569-000d3abe7d43',
  teamQueueId: '60f030f3-625c-ee11-be6e-6045bd8953b8',
  teamId: 'b0947e4c-87d4-ee11-9079-0022488b25ff',
  defaultCustomerId: '30cdf72f-8c48-ee11-be6d-000d3abe7d43',
  msalConfig: {
    auth: {
      clientId: 'd05e1248-d4e8-4a1c-85d7-c4c115498364',
      authority:
        'https://login.microsoftonline.com/c19312e5-3163-4dc8-ade8-c6d4e9efb70d/',
      clientSecret: 'pzk8Q~VHBsLBRag.EZ4ckYIy8.hE_2sBqXRH.aNu',
    },
  },
  apiConfig: {
    scopes: ['https://powerproductiondev.crm4.dynamics.com/user_impersonation'],
    // only used for local development
    uri: 'https://powerproductiondev.crm4.dynamics.com',
  },
  maxFileSize: 1024 * 1000 * 6, // 6MB
};
